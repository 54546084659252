import { useUser } from "hooks/user";
import { useMounted } from "hooks/react";

export default function SubscriptionTemplate(): JSX.Element {
  const { user } = useUser();
  const buttonUrl = "/cadastro?redirect_to=/assinatura";

  const mounted = useMounted();

  return (
    <div className="w-[40rem] mx-auto max-w-full md:mt-12">
      {mounted && user &&

        (
          <div>
            <h1 className="block text-center text-emphasis-high text-5xl font-bold">
              Questões ilimitadas
            </h1>
            <p className="text-center text-emphasis-medium">
              Com o &quot;Vstiba Total&quot;, você tem a liberdade de praticar sem limites, a qualquer hora e lugar, garantindo um preparo completo para enfrentar os desafios do ENEM e dos vestibulares.
            </p>
            <p className="text-center text-emphasis-medium">
              Eleve sua preparação ao próximo nível. Juntos, podemos tornar seu sonho universitário uma realidade.
            </p>

            {/* @ts-ignore */}
            <stripe-pricing-table pricing-table-id="prctbl_1P6gRAP22FKaZVzz92CC0xeO" publishable-key="pk_live_51Ow6BhP22FKaZVzzvHYVDdID5IBMUJGjPT2nJAUFrnGKigxkEhNoGbit5nuJWHLUiuKQmgNTz4qAHVRKDwzeVrYv00kFwDWiUi"></stripe-pricing-table>
          </div>
        )

        ||

        (
          <div className="w-[40rem] mx-auto max-w-full md:mt-12">
            <h1 className="block text-center text-emphasis-high text-5xl font-bold">
              Questões ilimitadas
            </h1>
            <p className="text-center text-emphasis-medium">
              Com o &quot;Vstiba Total&quot;, você tem a liberdade de praticar sem limites, a qualquer hora e lugar, garantindo um preparo completo para enfrentar os desafios do ENEM e dos vestibulares.
            </p>
            <p className="text-center text-emphasis-medium">
              Eleve sua preparação ao próximo nível. Juntos, podemos tornar seu sonho universitário uma realidade.
            </p>
            <div className="flex flex-col mt-12 mx-auto w-96 max-w-full bg-white border border-gray-100 rounded-xl shadow-lg overflow-hidden">
              <div className="h-100 p-8">
                <span className="block mb-3 text-xl font-semibold">
                  Estude sem restrições
                </span>
                <span className="block text-emphasis-medium">
                  Aproveite todas as funcionalidades da plataforma.
                </span>
              </div>
              <div className="action h-100 fade-loading p-8 bg-surface">
                <div className="text-center">
                  <span className="text-5xl font-bold">R$ 14,90</span>{" "}
                  <span className="text-emphasis-medium text-lg">/ mês</span>
                </div>
                <span className="block mb-8 mt-4 text-center text-emphasis-low">
                  Cancele a qualquer momento.
                </span>
                <a
                  href={buttonUrl}
                  className="btn btn-primary fade-loading w-full"
                >
                  Assinar agora
                </a>
              </div>
            </div>
          </div>
        )
      }
    </div>
  );
}
